ko.bindingHandlers.datepicker = {
  init: function (element, valueAccessor, allBindingsAccessor) {
	//initialize datepicker with some optional options
	var $el = $(element);

	InitializeDatePicker($el);

	//handle the field changing by registering datepicker's changeDate event
	ko.utils.registerEventHandler(element, "dp.change", function () {
	  var observable = valueAccessor();
	  var currentDate = $el.data("DateTimePicker").date();
	  observable(currentDate ? currentDate.format("MM/DD/YYYY HH:mm:ss") : null);
	});

	//handle disposal (if KO removes by the template binding)
	ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
	  $el.data("DateTimePicker").destroy();
	});
  },
  update: function (element, valueAccessor) {
	var value = ko.utils.unwrapObservable(valueAccessor()),
	  $el = $(element);

	//handle date data coming via json from Microsoft
	if (String(value).indexOf('/Date(') === 0)
	  value = new Date(parseInt(value.replace(/\/Date\((.*?)\)\//gi, "$1")));

	var current = $el.data("DateTimePicker").date();

	if (!current || (value - current.toDate() !== 0))
	  $el.data("DateTimePicker").date(value);
  }
};